<template>
  <article>
    <RouterNav :name="studentName"></RouterNav>
    <section class="section">
      <div class="title">
        <div class="left">
          <div class="line"></div>
          <div>数据概览</div>
          <div class="icon"></div>
          <div class="tips">在此期间，<span class="col-329cf3">{{studentName}}</span>共完成了<span class="col-329cf3">{{arrangeSum}}</span>次考试。整体完成情况如下：</div>
        </div>
      </div>
      <div class="echarts" ref="content">
        <div class="charts-wrapper" ref="main">
          <barForReport ref="barCharts"></barForReport>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="title">
        <div class="left">
          <div class="ml-28">得分成长趋势</div>
        </div>
      </div>
      <div class="charts" ref="lineContent">
        <div class="radio">
          <el-radio-group v-model="scoreGrowthIndex" @change="scoreGrowthChange">
            <el-radio class="mr-14" :label="index" v-for="(item, index) in scoreGrowthList" :key="index">{{item.questionTypeName}}</el-radio>
          </el-radio-group>
        </div>
        <div class="list" ref="lineWrapper">
          <LineCharts ref="line"></LineCharts>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="title">
        <div class="left">
          <div class="line"></div>
          <div>完成考试列表</div>
        </div>
      </div>
      <div class="table">
        <el-table
          :data="examList">
          <el-table-column
            prop="examName"
            label="考试名称"
            align="left"
            width="520"
          ></el-table-column>
          <el-table-column
            label="得分率"
            sortable
            sort-by="scoreRate"
            align="center"
          >
            <template slot-scope="props">
              <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <div class="link" @click="examDetail(scope.row.id, scope.row.examName)">查看作业详情</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="more-btn" @click="getMore" v-if="!nomore">
        <div>查看更多</div>
        <img src="../../../assets/z-1.png" alt="more">
      </div>
    </section>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav'
import barForReport from '../components/barForReport'
import LineCharts from '../components/LineCharts'

export default {
  name: 'userExamReport',
  components: {
    RouterNav,
    barForReport,
    LineCharts
  },
  data () {
    return {
      studentName: '',
      arrangeSum: 0,
      scoreGrowthList: [],
      scoreGrowthIndex: 0,
      examList: [],
      page: 1,
      pageSize: 10,
      nomore: false
    }
  },
  mounted () {
    document.getElementById('app').scrollTop = 0
    const { query } = this.$route
    this.studentName = query.name
    this.type = query.type
    this.classId = query.classId
    this.id = query.id
    this.getPersonalReportExam()
  },
  methods: {
    getPersonalReportExam () {
      this.$axios({
        method: 'post',
        url: this.$urls.getPersonalReportExam,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          studentId: this.id,
          classId: this.classId,
          type: this.type
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            this.arrangeSum = data.arrangeSum
            const { questionType, scoreGrowth } = data
            const questionTypeObj = {
              name: '得分率',
              xNameList: [],
              myScoreRateList: [],
              allScoreRateList: []
            }
            for (let i = 0, len = questionType.length; i < len; i += 1) {
              questionTypeObj.xNameList.push(questionType[i].questionTypeName)
              questionTypeObj.myScoreRateList.push(questionType[i].myScoreRate)
              questionTypeObj.allScoreRateList.push(questionType[i].allScoreRate)
            }
            this.initCharts(questionTypeObj)
            const scoreGrowthList = []
            for (let i = 0, len = scoreGrowth.length; i < len; i += 1) {
              const {questionTypeName, examInfo} = scoreGrowth[i]
              const obj = {
                questionTypeName,
                xNameList: [],
                myScoreRateList: [],
                allScoreRateList: []
              }
              for (let j = 0, len = examInfo.length; j < len; j += 1) {
                obj.xNameList.push(examInfo[j].examName)
                obj.myScoreRateList.push(examInfo[j].myscoreRate)
                obj.allScoreRateList.push(examInfo[j].allScoreRate)
              }
              scoreGrowthList.push(obj)
            }
            if (scoreGrowthList.length > 0) {
              this.scoreGrowthList = scoreGrowthList
              this.initLineCharts(scoreGrowthList[0])
            }
            this.getPersonalExamList()
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    initCharts (obj) {
      const { offsetWidth } = this.$refs.content
      const listWidth = obj.xNameList.length * 134
      let barWidth
      if (listWidth > offsetWidth) {
        this.$refs.main.style.width = `${listWidth}px`
        barWidth = listWidth
      } else {
        barWidth = offsetWidth
      }
      const option = {
        grid: {
          left: '35',
          right: '0'
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const { dataIndex } = params
            const res = `<p>${params.name}</p><p>当前学生：平均得分率：${obj.myScoreRateList[dataIndex]}%</p><p>全部学生：平均得分率：${obj.allScoreRateList[dataIndex]}%</p>`
            return res
          }
        },
        legend: {
          selectedMode: false,
          data: ['当前学生', '全部学生'],
          right: 0
        },
        xAxis: {
          axisLine: {show: false},
          axisTick: {show: false},
          show: true,
          textStyle: {
            color: '#666'
          },
          emphasis: {
            color: '#fff'
          },
          data: obj.xNameList
        },
        yAxis: {
          name: '得分率(%)',
          max: 100,
          min: 0
        },
        series: [{
          name: '当前学生',
          type: 'bar',
          barGap: 0,
          barWidth: 30,
          data: obj.myScoreRateList,
          itemStyle: {
            color: '#309AF2',
            label: {
              show: true,
              formatter: '{c}人',
              position: 'top',
              textStyle: {
                color: 'black',
                fontSize: 16
              }
            }
          }
        }, {
          name: '全部学生',
          type: 'bar',
          barWidth: 30,
          data: obj.allScoreRateList,
          itemStyle: {
            color: '#E5E5E5',
            label: {
              show: true,
              formatter: '{c}人',
              position: 'top',
              textStyle: {
                color: 'black',
                fontSize: 16
              }
            }
          }
        }]
      }
      this.$refs.barCharts.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    initLineCharts (obj) {
      const echarts = require('echarts')
      const { offsetWidth } = this.$refs.lineContent
      const listWidth = obj.xNameList.length * 134
      let barWidth
      if (listWidth > offsetWidth) {
        barWidth = listWidth
      } else {
        barWidth = offsetWidth
      }
      this.$refs.lineWrapper.style.width = `${barWidth}px`
      const option = {
        grid: {
          left: '35',
          right: '0'
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const { dataIndex } = params
            const res = `<p>${params.name}</p><p>当前学生：平均得分率：${obj.myScoreRateList[dataIndex]}%</p><p>全部学生：平均得分率：${obj.allScoreRateList[dataIndex]}%</p>`
            return res
          }
        },
        legend: {
          selectedMode: false,
          data: ['当前学生', '全部学生'],
          right: 0
        },
        xAxis: {
          show: false,
          type: 'category',
          data: obj.xNameList
        },
        yAxis: {
          name: '得分率(%)',
          max: 100,
          min: 0
        },
        series: [{
          name: '全部学生',
          data: obj.allScoreRateList,
          type: 'line',
          itemStyle: {
            color: '#C8C8C8'
          },
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0, 0, 0, .1)'
            }, {
              offset: 1,
              color: 'rgba(0, 0, 0, 0)'
            }])
          }
        }, {
          name: '当前学生',
          data: obj.myScoreRateList,
          type: 'line',
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(48, 154, 242, .1)'
            }, {
              offset: 1,
              color: 'rgba(48, 154, 242, 0)'
            }])
          }
        }]
      }
      this.$refs.line.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    scoreGrowthChange () {
      const { scoreGrowthList, scoreGrowthIndex } = this
      this.initLineCharts(scoreGrowthList[scoreGrowthIndex])
    },
    getPersonalExamList (page) {
      const pageIndex = page || this.page
      this.$axios({
        method: 'post',
        url: this.$urls.getPersonalExamList,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          studentId: this.id,
          classId: this.classId,
          type: this.type,
          page: pageIndex,
          pageSize: this.pageSize
        }
      })
        .then((response) => {
          const { pageContents } = response.data
          this.nomore = pageContents.length < this.pageSize
          this.examList = page > 1 ? [...this.examList, ...pageContents] : pageContents
          if (page) this.page = page
        })
    },
    getMore () {
      this.getPersonalExamList(this.page + 1)
    },
    examDetail (id, name) {
      this.$router.push({
        name: 'examDetail',
        query: {
          examId: id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .section {
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      padding-right: 28px;
      @include font;
      font-weight: bold;
      &:hover {
        .hidden {
          visibility: visible;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      .icon {
        background-image: url("../../../assets/icon-2.png");
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      .tips {
        background-color: #EAF4FD;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: normal;
      }
      .right {
        display: flex;
        align-items: center;
        color: #309AF2;
        font-size: 14px;
      }
      .btn {
        background: #EAF4FD url("../../../assets/z-1.png") no-repeat center center;
        background-size: 11px 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
      }
      .loading-btn {
        background: #EAF4FD url("../../../assets/loading.png") no-repeat center center;
        background-size: 16px 16px;
        animation: rotate .5s linear infinite;
      }
    }
    .echarts {
      border: 1px solid #E5E5E5;
      margin: 0 28px 20px;
      overflow: auto;
    }
    .charts {
      margin: 0 28px 20px;
      border: 1px solid #E5E5E5;
      overflow: auto;
      .radio {
        padding: 12px 14px 0;
      }
    }
    .table {
      padding: 0 28px 20px;
      .link {
        color: #309AF2;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .more-btn {
      @include flex;
      background-color: #F6F6F6;
      width: 120px;
      height: 32px;
      margin: 0 auto 40px;
      border-radius: 20px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 7px;
        height: 4px;
        margin-left: 4px;
      }
    }
  }
</style>
